import axios from "axios";
import toast from "react-hot-toast";
import getCookie from "../../../Component/getCookie";

export const uploadImages = async (
  browsedFiles,
  setUploading,
  setBrowsedFiles,
  fileInputRef
) => {
  setUploading(true);
  const results = [];

  if (browsedFiles?.length > 0) {
    for (let i = 0; i < browsedFiles.length; i++) {
      const image = new FormData();
      image.append("image", browsedFiles[i]);

      try {
        const response = await axios.post(
          `https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_PHOTO_URL}`,
          image
        );

        if (response.data.data.url) {
          results.push({ img: response.data.data.url });
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  }

  if (results.length > 0) {
    axios
      .post(`${process.env.REACT_APP_URL}/upload-images`, results, {
        headers: {
          Authorization: `Bearer ${getCookie("khoboraccess_token")}`,
        },
      })
      .then((res) => {
        if (res?.data?.acknowledged) {
          toast.success("Image upload success");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  setUploading(false);
  setBrowsedFiles([]);
  if (fileInputRef.current) {
    fileInputRef.current.value = "";
  }
};
